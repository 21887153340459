
import { EnterpriseTypes } from "@/store/modules/enterprise/enterprise.types";
import { Component, Ref, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const enterprise = namespace("Enterprise");

@Component({
  components: {
    EnterpriseList: () => import("@/components/enterprise/EnterpriseList.vue"),
  },
})
export default class EnterprisesView extends Vue {
  @enterprise.Mutation(EnterpriseTypes.SET_ADD_ENTERPRISE_DIALOG)
  public setAddEnterprise!: (addEnterprise: boolean) => void;

  @enterprise.State("loadingEnterpriseWithVarieties")
  public loadingEnterprises!: boolean;

  public pageSizes = [10, 15, 20];

  public pageSize = 15;

  public query = "";

  @Ref("enterpriseList") public enterpriseListRef!: {
    queryEnterprises(): void;
  };

  refresh(): void {
    this.enterpriseListRef.queryEnterprises();
  }

  openAddEnterpriseDialog(): void {
    this.setAddEnterprise(true);
  }
}
